<template>
  <v-simple-table dense v-if="value">
    <template #default>
      <tbody>
        <tr>
          <td>Lat:</td>
          <td>
            {{ value.lat | formatDecimalValue }}
          </td>
        </tr>
        <tr>
          <td>Lng:</td>
          <td>
            {{ value.lng | formatDecimalValue }}
          </td>
        </tr>
        <tr v-if="value.distance">
          <td>Distance:</td>
          <td>
            {{ value.distance | formatDecimalValue }}
          </td>
        </tr>
        <tr v-if="value.angle">
          <td>Angle:</td>
          <td>
            {{ value.angle | formatDecimalValue }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script lang="ts">
export default {
  props: {
    value: {
      type: Object,
      required: true,
      default: undefined,
    },
  },
  filters: {
    formatDecimalValue(value: string) {
      return parseFloat(value).toFixed(0);
    },
  },
};
</script>
