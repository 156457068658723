import { serviceWorkerUpdated } from "@/common/constants";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      installationPrompt: null,
      updateExists: false,
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      if (Cookies.get("install-serviceworker") === undefined) {
        this.installationPrompt = event;
      }
    });
    window.addEventListener("appinstalled", () => {
      this.installationPrompt = null;
    });
    document.addEventListener(serviceWorkerUpdated, this.updateAvailable, {
      once: true,
    });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) {
        return;
      }
      this.refreshing = true;
      window.location.reload();
    });
  },
  methods: {
    async dismiss() {
      Cookies.set("install-serviceworker", null, { expires: 7 });
      this.installationPrompt = null;
    },
    async install() {
      this.installationPrompt.prompt();
    },
    async updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },
    async refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
};
