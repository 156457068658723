<template>
  <span>
    <v-navigation-drawer
      app
      clipped
      fixed
      width="180"
      :mini-variant.sync="navigationDrawerPinned"
      permanent
      touchless
    >
      <span v-for="(theater, i) in theaters()" :key="i">
        <v-list dense nav subheader>
          <v-subheader>{{ theater.name }}</v-subheader>
          <v-list-item
            :to="{
              name: 'MapLocation',
              params: {
                name: theater.name,
              },
            }"
          >
            <v-list-item-content>
              <v-list-item-title>Map</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :to="{
              name: 'MapTheaterTableViewer',
              params: {
                name: theater.name,
              },
            }"
          >
            <v-list-item-content>
              <v-list-item-title>Overview</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <document-list
            v-for="(document, l) in theaterDocuments(theater.name)"
            :key="'document_' + l"
            :id="document.id"
            route-name="MapDocument"
          />
          <v-list-group
            v-for="(locationGroup, j) in theater.locationGroups"
            :key="j"
          >
            <template #activator>
              <v-list-item-content>
                <v-list-item-title>{{ locationGroup.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(location, k) in sortedLocations(locationGroup)"
              :key="k"
              :location="location"
              :to="{
                name: 'MapLocation',
                params: {
                  name: theater.name,
                  x: location.coordinatesInFalconUnits.x,
                  y: location.coordinatesInFalconUnits.y,
                },
              }"
            >
              <v-list-item-content two-line>
                <v-list-item-title>{{ location.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ location.owner }}
                  <span v-if="location.tacanChannel && location.tacanBand">
                    &nbsp;{{ location.tacanChannel + location.tacanBand }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="location.charts.length > 0">
                <v-icon color="grey lighten-1" small>
                  {{ mdiMap }}
                </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-divider v-if="i < Object.keys(theaters()).length - 1"></v-divider>
      </span>
      <template #append>
        <v-toolbar dense>
          <v-btn
            ripple
            plain
            icon
            @click="navigationDrawerPinned = !navigationDrawerPinned"
            v-if="$vuetify.breakpoint.mdAndDown"
          >
            <v-icon v-if="navigationDrawerPinned">{{ mdiChevronRight }}</v-icon>
            <v-icon v-else>{{ mdiChevronLeft }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-menu z-index="220">
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item>
                <v-list-item-action>
                  <v-switch dense v-model="sortByOwner"></v-switch>
                </v-list-item-action>
                <v-list-item-title>Sort Locations By Owner</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </template>
    </v-navigation-drawer>
    <router-view></router-view>
  </span>
</template>

<script>
import DocumentList from "@/components/DocumentList.vue";
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiDotsVertical,
  mdiMap,
} from "@mdi/js";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  components: {
    DocumentList,
  },
  computed: {
    ...mapGetters(["theaters", "theaterDocuments"]),
  },
  props: {
    name: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      mdiChevronLeft,
      mdiChevronRight,
      mdiDotsVertical,
      mdiMap,
      navigationDrawerPinned: false,
      sortByOwner: false,
      showMap: true,
    };
  },
  methods: {
    sortedLocations(locationGroup) {
      if (this.sortByOwner) {
        return [...locationGroup.locations].sort((a, b) => {
          const byOwner = a.owner.localeCompare(b.owner);
          if (byOwner !== 0) {
            return byOwner;
          }
          return a.name.localeCompare(b.name);
        });
      }
      return locationGroup.locations;
    },
  },
});
</script>
