import { LatLngLiteral, LeafletMouseEvent, Map } from "leaflet";

type Point = {
  x: number;
  y: number;
};

type LatLngWithPoint = LatLngLiteral & {
  x: number;
  y: number;
};

export type LatLngWithDistanceAndAngle = LatLngWithPoint & {
  distance?: number;
  angle?: number;
};

const calculateAngle = (anchor: Point, point: Point): number => {
  let angle =
    (Math.atan2(anchor.y - point.y, anchor.x - point.x) * 180) / Math.PI - 90;
  angle += angle < 0 ? 360 : 0;
  return angle;
};

export const toLatLngWithDistanceAndAngle = (
  anchor: LatLngWithPoint,
  event: LeafletMouseEvent,
  map: Map,
): LatLngWithDistanceAndAngle => {
  const distance = anchor ? map.distance(event.latlng, anchor) : undefined;
  const angle = anchor ? calculateAngle(anchor, event.layerPoint) : undefined;
  return {
    ...event.latlng,
    x: event.layerPoint.x,
    y: event.layerPoint.y,
    distance,
    angle,
  };
};
