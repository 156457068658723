<template>
  <v-container v-if="entry" fluid>
    <p class="text-h6">{{ entry.name }}</p>
    <v-row class="pb-6">
      <v-col cols="12" order="1" md="6" order-md="0">
        <v-row>
          <v-col
            v-for="(dataValue, dataKey) in entry.data"
            :key="dataKey"
            cols="12"
            sm="6"
          >
            <p class="subtitle-2">{{ dataKey | wordize }}</p>
            <p class="body-2" style="white-space: pre-line">
              {{ dataValue.trim() }}
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" order="0" md="6" order-md="1">
        <v-carousel height="auto">
          <v-carousel-item v-for="(imageFilePath, j) in entry.images" :key="j">
            <v-img
              class="tacref-image mx-auto"
              contain
              :aspect-ratio="1"
              :max-height="960"
              :src="imageUrl(imageFilePath)"
            >
              <template #placeholder>
                <v-skeleton-loader
                  :max-height="960"
                  type="card, image, image"
                ></v-skeleton-loader>
              </template>
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="pt-6">
      <v-col>
        <p class="subtitle-2">Description</p>
        <p class="body-2" style="white-space: pre-line">
          {{ entry.description }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.tacref-image >>> .v-image__image--contain {
  background-position: center top !important;
}
</style>

<script>
import { baseUrl } from "@/appUrl";
import words from "lodash/words";
import capitalize from "lodash/capitalize";

export default {
  props: {
    categoryId: {
      type: String,
      required: true,
    },
    subCategoryId: {
      type: String,
      required: true,
    },
    entryId: {
      type: String,
      required: true,
    },
  },
  computed: {
    entry() {
      return this.$store.getters.tacticalReferenceEntry(
        this.categoryId,
        this.subCategoryId,
        this.entryId,
      );
    },
  },
  data() {
    return {
      defaultImageHeight: 960, // All images have a height of 960
    };
  },
  methods: {
    imageUrl(imageFilePath) {
      return new URL(`./data/tacref/${imageFilePath}`, baseUrl).toString();
    },
  },
  filters: {
    wordize(text) {
      return words(text)
        .map((word) => capitalize(word))
        .join(" ");
    },
  },
};
</script>
