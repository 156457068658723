<template>
  <span>
    <v-navigation-drawer
      app
      clipped
      fixed
      width="180"
      :mini-variant.sync="navigationDrawerPinned"
      permanent
      touchless
    >
      <v-list dense nav>
        <document-list
          v-for="(document, i) in documents(category)"
          :key="i"
          :id="document.id"
        />
      </v-list>
      <template #append>
        <v-toolbar dense>
          <v-btn
            ripple
            plain
            icon
            @click="navigationDrawerPinned = !navigationDrawerPinned"
            v-if="$vuetify.breakpoint.mdAndDown"
          >
            <v-icon v-if="navigationDrawerPinned">{{ mdiChevronRight }}</v-icon>
            <v-icon v-else>{{ mdiChevronLeft }}</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
    </v-navigation-drawer>
    <router-view></router-view>
  </span>
</template>

<script lang="ts">
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Vue from "vue";
import { mapGetters } from "vuex";
import DocumentList from "@/components/DocumentList.vue";

export default Vue.extend({
  components: {
    DocumentList,
  },
  computed: {
    ...mapGetters(["documents"]),
  },
  data() {
    return {
      mdiChevronLeft: mdiChevronLeft,
      mdiChevronRight: mdiChevronRight,
      navigationDrawerPinned: false,
    };
  },
  props: {
    category: {
      type: String,
      required: false,
      default: undefined,
    },
  },
});
</script>
