<template>
  <v-list-group v-if="document">
    <template #activator>
      <v-list-item-content>
        <v-list-item-title
          :to="{
            name: routeName,
            params: {
              id,
            },
          }"
        >
          {{ document.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <v-list-item
      v-for="(topic, j) in document.topics"
      :key="j"
      :to="{
        name: routeName,
        params: {
          id,
          page: topic.page,
        },
      }"
    >
      <v-list-item-content>
        <v-list-item-title>{{ topic.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  computed: {
    document() {
      return this.$store.getters.document(this.id);
    },
  },
  props: {
    id: {
      type: String,
      required: true,
      default: undefined,
    },
    routeName: {
      type: String,
      required: false,
      default: "Document",
    },
  },
});
</script>
