type SteerpointActions = { [key: number]: string | null };

// see https://github.com/FreeFalcon/freefalcon-central/blob/c28d807183ab447ef6a801068aa3769527d55deb/src/campaign/include/campwp.h#L19
const steerpointActions: SteerpointActions = {
  0: null,
  1: "Takeoff",
  2: "Assemble",
  3: "Post Assemble",
  4: "Refuel",
  5: "Rearm",
  6: "Pickup",
  7: "Land",
  8: "Flight",
  9: "CAS CP",
  10: "Escort",
  11: "Sweep",
  12: "CAP",
  13: "Intercept",
  14: "Ground Strike",
  15: "Naval Strike",
  16: "Seek And Destroy",
  17: "Strike",
  18: "Strategic Bombing",
  19: "SEAD",
  20: "ELINT",
  21: "Recon",
  22: "SAR",
  23: "ASW",
  24: "Tanker",
  25: "Air Drop",
  26: "ECM",
  27: "Airlift",
  28: "B5",
  29: "B6",
  30: "FAC",
  31: null,
  32: null,
  33: null,
  34: null,
  35: null,
  36: null,
  37: null,
  38: null,
  39: null,
  40: "Move",
  41: "Move",
  42: "Airborne",
  43: "Amphibious",
  44: "Defend",
  45: "Repair",
  46: "Reserve",
  47: "Air Defense",
  48: "Fire Support",
  49: "Secure",
  50: "Last",
};

export const getSteerpointAction = (index: number): string | null => {
  if (index && index >= 0 && index <= 50) {
    return steerpointActions[index];
  } else {
    return null;
  }
};
