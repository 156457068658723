import {
  Line,
  LinePoint,
  MissionData,
  Steerpoint,
  Threat,
  WeaponTarget,
} from "@/common/mission-typings";
import { getSteerpointAction } from "@/common/missions";
import { partition } from "@/common/partition";
import { toFalconUnit } from "@/common/toFalconUnit";
import { toNumber } from "@/common/toNumber";
import ini from "ini";

export const readIniFile = (
  binaryContents: Blob,
  // eslint-disable-next-line no-unused-vars
  callback: (missionData: MissionData) => void,
): void => {
  const reader = new FileReader();
  if (!binaryContents) {
    return undefined;
  }
  reader.readAsText(binaryContents);
  reader.onload = () => {
    if (!reader.result) {
      return;
    }
    const stringContents = reader.result?.toString();
    const parsedIniFile = ini.parse(stringContents);
    if (!(parsedIniFile.MISSION && parsedIniFile.STPT)) {
      return;
    }
    const steerpoints: Steerpoint[] = [];
    for (const index of Array(100).keys()) {
      const string = parsedIniFile.STPT["target_" + index];
      if (string) {
        const tokens = string.split(",");
        if (tokens.length >= 4 && tokens[0] !== "0.000000") {
          const actionIndex = toNumber(tokens[3], 0);
          const steerpointAction = getSteerpointAction(actionIndex);
          steerpoints.push({
            title: "Steerpoint " + (index + 1),
            type: "steerpoint",
            x: toFalconUnit(tokens[0], 0),
            y: toFalconUnit(tokens[1], 0),
            z: toFalconUnit(tokens[2], 0),
            action: steerpointAction,
          });
        }
      }
    }
    const weaponTargets: WeaponTarget[] = [];
    for (const index of Array(100).keys()) {
      const string = parsedIniFile.STPT["wpntarget_" + index];
      if (string) {
        const tokens = string.split(",");
        if (tokens.length >= 4 && tokens[0] !== "0.000000") {
          weaponTargets.push({
            title: "Target " + (index + 1),
            type: "target",
            x: toFalconUnit(tokens[0], 0),
            y: toFalconUnit(tokens[1], 0),
            z: toFalconUnit(tokens[2], 0),
          });
        }
      }
    }
    const threats: Threat[] = [];
    for (const index of Array(15).keys()) {
      const string = parsedIniFile.STPT["ppt_" + index];
      if (string) {
        const tokens = string.split(",");
        if (tokens.length >= 5 && tokens[0] !== "0.000000") {
          threats.push({
            title: tokens[4]?.trim(),
            type: "threat",
            x: toFalconUnit(tokens[0], 0),
            y: toFalconUnit(tokens[1], 0),
            z: toFalconUnit(tokens[2], 0),
            radius: toFalconUnit(tokens[3], 0),
          });
        }
      }
    }
    const lines: Line[] = [];
    const arrays = partition(Array.from(Array(20).keys()), 5);
    for (const array of arrays) {
      const points: LinePoint[] = [];
      for (const index of array) {
        const string = parsedIniFile.STPT["lineSTPT_" + index];
        if (string) {
          const tokens = string.split(",");
          if (tokens.length >= 3 && tokens[0] !== "0.000000") {
            points.push({
              title: "Line" + (index + 1),
              type: "line",
              x: toFalconUnit(tokens[0], 0),
              y: toFalconUnit(tokens[1], 0),
              z: toFalconUnit(tokens[2], 0),
            });
          }
        }
      }
      lines.push({
        points,
      });
    }
    callback({
      title: parsedIniFile.MISSION.title,
      steerpoints,
      weaponTargets,
      threats,
      lines,
    });
  };
};
