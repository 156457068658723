<template>
  <div>
    <v-skeleton-loader type="card-avatar, article, actions"></v-skeleton-loader>
    <v-overlay>
      <v-alert prominent type="info" :icon="mdiLock">
        <h3 class="text-h5">Authentication Required</h3>
        <div>
          Certain parts of this website are reserved for members of the 1st
          Glory Wings.
        </div>
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col class="grow">
            If you choose to sign in, we'll redirect you to
            <strong>https://www.1stglorywings.com</strong> and you can choose to
            login with your existing account or create a new one.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="shrink">
            <v-btn @click="$router.back()">Go Back</v-btn>
          </v-col>
          <v-col class="shrink">
            <v-btn class="primary" @click="authenticateOidc">Sign In</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mdiLock } from "@mdi/js";

export default {
  name: "AuthenticationRequiredComponent",
  data() {
    return {
      mdiLock: mdiLock,
      redirectPath: undefined,
    };
  },
  methods: {
    ...mapActions(["authenticateOidc"]),
  },
};
</script>
