import { toNumber } from "@/common/toNumber";
import AuthenticationCallback from "@/components/AuthenticationCallback.vue";
import DocumentNavigator from "@/components/DocumentNavigator.vue";
import DocumentViewer from "@/components/DocumentViewer.vue";
import MapNavigator from "@/components/MapNavigator.vue";
import MapTheaterTableViewer from "@/components/MapTheaterTableViewer.vue";
import MapViewer from "@/components/MapViewer.vue";
import TacticalReferenceNavigator from "@/components/TacticalReferenceNavigator.vue";
import TacticalReferenceViewer from "@/components/TacticalReferenceViewer.vue";
import store from "@/store";
import Home from "@/views/Home.vue";
import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";

export const DOCUMENT_ROUTE_NAME = "Document";
export const DOCUMENTS_ROUTE_NAME = "Documents";

const documentRoutes = [DOCUMENT_ROUTE_NAME, DOCUMENTS_ROUTE_NAME];

export const isDocumentRoute = (route: Route) => {
  return route.name && documentRoutes.includes(route.name);
};

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/documents/:category",
    name: DOCUMENTS_ROUTE_NAME,
    props: (route) => ({
      category: route.params.category,
    }),
    component: DocumentNavigator,
    children: [
      {
        path: ":id/:page?",
        name: DOCUMENT_ROUTE_NAME,
        props: (route) => ({
          id: route.params.id,
          page: toNumber(route.params.page, 1),
        }),
        component: DocumentViewer,
      },
    ],
  },
  {
    path: "/tacref",
    name: "TacticalReference",
    component: TacticalReferenceNavigator,
    children: [
      {
        path: ":categoryId/:subCategoryId/:entryId",
        name: "TacticalReferenceViewer",
        props: (route) => ({
          categoryId: route.params.categoryId,
          subCategoryId: route.params.subCategoryId,
          entryId: route.params.entryId,
        }),
        component: TacticalReferenceViewer,
      },
    ],
  },
  {
    path: "/maps/:name?",
    name: "Map",
    props: (route) => ({
      name: route.params.name,
    }),
    component: MapNavigator,
    children: [
      {
        path: "location/:x?/:y?",
        name: "MapLocation",
        props: (route) => ({
          name: route.params.name,
          x: toNumber(route.params.x, undefined),
          y: toNumber(route.params.y, undefined),
        }),
        component: MapViewer,
      },
      {
        path: "table",
        name: "MapTheaterTableViewer",
        props: (route) => ({
          name: route.params.name,
        }),
        component: MapTheaterTableViewer,
      },
      {
        path: "document/:id/:page?",
        name: "MapDocument",
        props: (route) => ({
          id: route.params.id,
          page: toNumber(route.params.page, 1),
        }),
        component: DocumentViewer,
      },
    ],
  },
  {
    path: "/authentication",
    name: "AuthenticationCallback",
    component: AuthenticationCallback,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store));

export default router;
