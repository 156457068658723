var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","fixed":"","width":"180","mini-variant":_vm.navigationDrawerPinned,"permanent":"","touchless":""},on:{"update:miniVariant":function($event){_vm.navigationDrawerPinned=$event},"update:mini-variant":function($event){_vm.navigationDrawerPinned=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-toolbar',{attrs:{"dense":""}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-btn',{attrs:{"ripple":"","plain":"","icon":""},on:{"click":function($event){_vm.navigationDrawerPinned = !_vm.navigationDrawerPinned}}},[(_vm.navigationDrawerPinned)?_c('v-icon',[_vm._v(_vm._s(_vm.mdiChevronRight))]):_c('v-icon',[_vm._v(_vm._s(_vm.mdiChevronLeft))])],1):_vm._e()],1)]},proxy:true}])},_vm._l((_vm.tacticalReferenceCategories()),function(category,i){return _c('span',{key:i},[_c('v-list',{attrs:{"dense":"","nav":"","subheader":""}},[_c('v-subheader',[_vm._v(_vm._s(category.name))]),_vm._l((category.categories),function(subCategory,j){return _c('v-list-group',{key:j,scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(subCategory.name))])],1)]},proxy:true}],null,true)},_vm._l((_vm.sortedEntries(subCategory)),function(entry,k){return _c('v-list-item',{key:k,attrs:{"to":{
              name: 'TacticalReferenceViewer',
              params: {
                categoryId: category.id,
                subCategoryId: subCategory.id,
                entryId: entry.id,
              },
            }}},[_c('v-list-item-content',{attrs:{"two-line":""}},[_c('v-list-item-title',[_vm._v(_vm._s(entry.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(entry.owner))])],1)],1)}),1)})],2),(i < Object.keys(_vm.tacticalReferenceCategories()).length - 1)?_c('v-divider'):_vm._e()],1)}),0),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }