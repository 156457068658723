var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"disable-pagination":"","fixed-header":"","headers":_vm.headers,"hide-default-footer":"","item-key":"campaignId","items":_vm.locations,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:`item.name`,fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                name: 'MapLocation',
                params: {
                  name: _vm.name,
                  x: item.coordinatesInFalconUnits.x,
                  y: item.coordinatesInFalconUnits.y,
                },
              }}},[_c('strong',[_vm._v(_vm._s(item.name))])])]}},{key:`item.tacan`,fn:function({ item }){return [(item.tacanChannel)?_c('span',[_vm._v(" "+_vm._s(item.tacanChannel + item.tacanBand)+" ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }