<template>
  <v-col sm="4" lg="3" v-if="title && value">
    <strong>{{ title }}: </strong>
    <span v-if="value">{{ value }}</span>
  </v-col>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: undefined,
    },
    // eslint-disable-next-line vue/require-prop-types
    value: {
      required: false,
      default: undefined,
    },
  },
};
</script>
