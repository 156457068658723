<template>
  <span>
    <v-container class="fill-height" fluid>
      <l-map
        id="leafletMap"
        v-if="theater"
        style="height: 100%"
        ref="leafletMap"
        :crs="crs"
        :bounds="bounds"
        :center="center"
        :zoom="zoom"
        :min-zoom="minZoom"
        :max-zoom="maxZoom"
        :max-bounds="bounds"
        :max-bounds-viscosity="maxBoundsViscosity"
        :options="options"
        @ready="onReady()"
        @update:zoom="onZoom()"
      >
        <l-image-overlay
          :url="mapUrl"
          :bounds="bounds"
          :interactive="true"
          @click="onImageOverlayClick"
          @mousemove="onImageOverlayMove"
        >
        </l-image-overlay>
        <l-control-layers :collapsed="false" position="topright">
        </l-control-layers>
        <l-control position="bottomright">
          <v-toolbar
            :collapse="isToolbarCollapsed"
            dense
            rounded
            small
            floating
          >
            <v-btn icon small @click="isToolbarCollapsed = !isToolbarCollapsed">
              <v-icon v-if="isToolbarCollapsed">{{ mdiDotsHorizontal }}</v-icon>
              <v-icon v-else>{{ mdiDotsVertical }}</v-icon>
            </v-btn>
            <v-divider class="mx-1" vertical></v-divider>
            <v-btn text small :depressed="isRulerActive" @click="toggleRuler">
              <v-icon left>{{ mdiRulerSquare }}</v-icon>
              {{ isRulerActive ? "Stop" : "Ruler" }}
            </v-btn>
            <v-btn
              text
              small
              v-if="rulerCoordinates.length > 0"
              @click="clearRulerCoordinates"
            >
              <v-icon left>{{ mdiCancel }}</v-icon>
              Clear
            </v-btn>
            <v-divider class="mx-1" vertical></v-divider>
            <v-file-input
              accept=".ini"
              dense
              v-model="iniFile"
              filled
              small-chips
              hide-details="auto"
              truncate-length="15"
              placeholder=".ini File"
              @change="onFileSelected"
              @click:clear="onFileCleared"
            ></v-file-input>
          </v-toolbar>
        </l-control>
        <l-layer-group
          v-for="(layer, layerIndex) in layers.slice().reverse()"
          :ref="layer.id"
          :name="layer.name"
          :key="layer.id"
        >
          <l-circle-marker
            v-for="(marker, markerIndex) in layer.markers"
            :key="'circlemarker_location_' + marker.name + '_' + markerIndex"
            :lat-lng="marker.latLng"
            :radius="20"
            fill-color="magenta"
            :fill-opacity="0.2"
            color="magenta"
            :interactive="false"
            :weight="5"
            :visible="marker.selected()"
          />
          <l-marker
            v-for="(marker, markerIndex) in layer.markers"
            :icon="dynamicIcon"
            :lat-lng="marker.latLng"
            :key="'marker_location_' + marker.name + '_' + markerIndex"
            :title="marker.name"
            :opacity="0"
            @click="marker.onClick"
            :z-index-offset="layerIndex"
          >
            <l-tooltip
              :options="{
                offset: [10, 0],
                sticky: true,
              }"
              >{{ marker.name }}</l-tooltip
            >
          </l-marker>
        </l-layer-group>
        <map-mission-data-viewer v-if="iniFile" />
        <l-layer-group
          ref="ruler"
          name="Ruler"
          layer-type="overlay"
          v-if="rulerCoordinates.length > 0"
        >
          <l-circle
            v-for="(rulerCoordinate, index) in rulerCoordinates"
            :key="'circle_rulercoordinate_' + index"
            :lat-lng="rulerCoordinate"
            color="yellow"
            :weight="4"
            :opacity="0.8"
          >
          </l-circle>
          <l-polyline
            :lat-lngs="rulerCoordinatesWithTemporaryRulerCoordinate"
            color="yellow"
            :interactive="false"
            :weight="2"
            :opacity="0.8"
          >
          </l-polyline>
          <l-circle
            v-if="temporaryRulerCoordinate"
            key="circle_rulercoordinate_temp"
            :lat-lng="temporaryRulerCoordinate"
            color="yellow"
            :interactive="false"
            :weight="4"
            :opacity="0.8"
          >
            <l-tooltip
              :options="{
                offset: [10, 0],
                sticky: true,
                permanent: true,
              }"
            >
              <map-coordinate :value="temporaryRulerCoordinate" />
            </l-tooltip>
          </l-circle>
        </l-layer-group>
      </l-map>
      <v-bottom-sheet
        v-model="showMapLocationViewer"
        hide-overlay
        persistent
        scrollable
        :fullscreen="isLocationInFullscreen"
      >
        <v-card v-if="selectedLocation">
          <v-system-bar>
            <v-spacer></v-spacer>
            <v-icon
              v-if="isLocationInFullscreen"
              @click="isLocationInFullscreen = false"
            >
              {{ mdiWindowRestore }}
            </v-icon>
            <v-icon v-else @click="isLocationInFullscreen = true">
              {{ mdiWindowMaximize }}
            </v-icon>
            <v-icon @click="showMapLocationViewer = false">
              {{ mdiClose }}
            </v-icon>
          </v-system-bar>
          <v-card-title>{{ selectedLocation.name }}</v-card-title>
          <v-card-subtitle>{{ selectedLocation.owner }}</v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <map-location-viewer :location="selectedLocation" />
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
    </v-container>
  </span>
</template>

<style>
#leafletMap .leaflet-control-layers label {
  padding-top: 5px;
  padding-left: 5px;
}

#leafletMap .leaflet-control-layers-selector {
  margin-right: 2px;
}

#leafletMap .leaflet-tooltip-center {
  background-color: unset;
  border: unset;
  box-shadow: unset;
  color: white;
}
#leafletMap .leaflet-pane {
  z-index: 140;
}
#leafletMap .leaflet-tile-pane {
  z-index: 120;
}
#leafletMap .leaflet-overlay-pane {
  z-index: 140;
}
#leafletMap .leaflet-shadow-pane {
  z-index: 150;
}
#leafletMap .leaflet-marker-pane {
  z-index: 160;
}
#leafletMap .leaflet-tooltip-pane {
  z-index: 165;
}
#leafletMap .leaflet-popup-pane {
  z-index: 170;
}
#leafletMap .leaflet-map-pane canvas {
  z-index: 110;
}
#leafletMap .leaflet-map-pane svg {
  z-index: 120;
}
#leafletMap .leaflet-control {
  z-index: 180;
}
#leafletMap .leaflet-top,
#leafletMap .leaflet-bottom {
  z-index: 200;
}
#leafletMap .leaflet-zoom-box {
  z-index: 180;
}
</style>

<script>
import { baseUrl } from "@/appUrl";
import { toLatLngWithDistanceAndAngle } from "@/common/leaflet";
import MapCoordinate from "@/components/MapCoordinate.vue";
import MapLocationViewer from "@/components/MapLocationViewer.vue";
import MapMissionDataViewer from "@/components/MapMissionDataViewer.vue";
import {
  mdiCancel,
  mdiClose,
  mdiDotsHorizontal,
  mdiDotsVertical,
  mdiRulerSquare,
  mdiWindowMaximize,
  mdiWindowRestore,
} from "@mdi/js";
import L, { latLng } from "leaflet";
import {
  LCircle,
  LCircleMarker,
  LControl,
  LControlLayers,
  LImageOverlay,
  LLayerGroup,
  LMap,
  LMarker,
  LPolyline,
  LTooltip,
} from "vue2-leaflet";

export default {
  components: {
    LCircle,
    LCircleMarker,
    LControlLayers,
    LControl,
    LImageOverlay,
    LLayerGroup,
    LMap,
    LMarker,
    LPolyline,
    LTooltip,
    MapCoordinate,
    MapLocationViewer,
    MapMissionDataViewer,
  },
  computed: {
    bounds() {
      const theater = this.$store.getters.theater(this.name);
      const units = theater?.units ?? 1024;
      return [
        [0, 0],
        [units, units],
      ];
    },
    mapUrl() {
      return new URL(
        "./data/maps/" + this.theater.filePath,
        baseUrl,
      ).toString();
    },
    theater() {
      return this.$store.getters.theater(this.name);
    },
    layers() {
      const theater = this.$store.getters.theater(this.name);
      const isSelected = (location) => {
        return (
          this.x === location.coordinatesInFalconUnits.x &&
          this.y === location.coordinatesInFalconUnits.y
        );
      };
      const toMarker = (location) => {
        return {
          latLng: latLng(
            location.coordinatesInFalconUnits.y,
            location.coordinatesInFalconUnits.x,
          ),
          key: location.name,
          name: location.name,
          selected: () => isSelected(location),
          onClick: () => {
            this.$router
              .push({
                name: "MapLocation",
                params: {
                  name: theater.name,
                  x: location.coordinatesInFalconUnits.x,
                  y: location.coordinatesInFalconUnits.y,
                },
              })
              .catch(() => {
                //
              });
          },
        };
      };
      return theater.locationGroups?.map((locationGroup) => {
        return {
          id: locationGroup.name.toLowerCase(),
          name: locationGroup.name,
          markers: locationGroup.locations.map((location) =>
            toMarker(location),
          ),
        };
      });
    },
    dynamicIcon() {
      const multiplier =
        this.zoom <= 0 ? this.zoom - this.minZoom + 1 : this.zoom;
      const size = 12 * multiplier;
      return L.icon({
        iconUrl: require("../assets/pixel.png"),
        iconSize: [size, size],
        iconAnchor: [size / 2, size / 2],
      });
    },
    selectedLocation() {
      return this.$store.getters.theaterLocationByCoordinates(
        this.name,
        this.x,
        this.y,
      );
    },
    rulerCoordinatesWithTemporaryRulerCoordinate() {
      const rulerCoordinates = [];
      if (this.rulerCoordinates) {
        rulerCoordinates.push(...this.rulerCoordinates);
      }
      if (this.temporaryRulerCoordinate) {
        rulerCoordinates.push(this.temporaryRulerCoordinate);
      }
      return rulerCoordinates;
    },
  },
  props: {
    name: {
      type: String,
      default: null,
      required: false,
    },
    x: {
      type: Number,
      required: false,
      default: undefined,
    },
    y: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      crs: L.CRS.Simple,
      zoom: 1,
      center: [0, 0],
      minZoom: 0,
      maxZoom: 2,
      maxBoundsViscosity: 1.0,
      mapLoading: true,
      mapObject: null,
      mdiCancel,
      mdiClose,
      mdiDotsHorizontal,
      mdiDotsVertical,
      mdiRulerSquare,
      mdiWindowMaximize,
      mdiWindowRestore,
      options: { tap: false },
      isLocationInFullscreen: false,
      isRulerActive: false,
      isToolbarCollapsed: false,
      rulerCoordinates: [],
      temporaryRulerCoordinate: undefined,
      showMapLocationViewer: false,
      iniFile: null,
    };
  },
  watch: {
    x(newX) {
      this.onCoordinateChange(newX, this.y);
    },
    y(newY) {
      this.onCoordinateChange(this.x, newY);
    },
  },
  methods: {
    rulerOptions(index) {
      return {
        offset: [10, 0],
        sticky: true,
        permanent: this.rulerCoordinates.length - 1 === index,
      };
    },
    clearRulerCoordinates() {
      this.rulerCoordinates = [];
      this.temporaryRulerCoordinate = undefined;
    },
    toggleRuler() {
      this.isRulerActive = !this.isRulerActive;
      this.temporaryRulerCoordinate = undefined;
    },
    onCoordinateChange(x, y) {
      if (this.mapObject && x && y) {
        const coordinates = latLng(y, x);
        this.mapObject.setView(coordinates);
        this.showMapLocationViewer = this.selectedLocation !== null;
      }
    },
    onReady() {
      this.mapLoading = false;
      this.mapObject = this.$refs.leafletMap.mapObject;
      this.onCoordinateChange(this.x, this.y);
    },
    onZoom() {
      this.zoom = this.$refs.leafletMap.mapObject._zoom;
    },
    onImageOverlayClick(event) {
      if (this.isRulerActive) {
        const rulerCoordinate = toLatLngWithDistanceAndAngle(
          this.rulerCoordinates[this.rulerCoordinates.length - 1],
          event,
          this.mapObject,
        );
        this.rulerCoordinates.push(rulerCoordinate);
      }
      this.showMapLocationViewer = false;
      this.$router
        .push({
          name: "MapLocation",
          params: {
            name: this.name,
          },
        })
        .catch(() => {
          //
        });
    },
    onImageOverlayMove(event) {
      if (!this.isRulerActive) {
        return;
      }
      const rulerCoordinate = toLatLngWithDistanceAndAngle(
        this.rulerCoordinates[this.rulerCoordinates.length - 1],
        event,
        this.mapObject,
      );
      this.temporaryRulerCoordinate = rulerCoordinate;
    },
    onFileCleared() {
      this.$store.dispatch("clearMissionData");
    },
    onFileSelected() {
      if (this.iniFile) {
        this.$store.dispatch("parseMissionData", this.iniFile);
      }
    },
  },
};
</script>
