export const toNumber = <D extends number | undefined>(
  text: string,
  defaultValue: D,
): number | D => {
  const textAsNumber = Number.parseFloat(text);
  if (Number.isNaN(textAsNumber)) {
    return defaultValue;
  }
  return textAsNumber;
};
