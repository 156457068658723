<template>
  <div>
    <v-overlay v-if="error">
      <v-alert type="error" prominent>
        <h3 class="text-h5">Authentication Failed</h3>
        <div>We have received an error message upon authentication.</div>
        <v-divider class="my-3"></v-divider>
        <v-row align="center" no-gutters>
          <v-col class="grow">
            {{ error }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="shrink">
            <v-btn @click="$router.push('Home')">Go Home</v-btn>
          </v-col>
          <v-col class="shrink">
            <v-btn class="primary" @click="authenticateOidc">Try again</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-overlay>
    <v-overlay v-else>
      <v-alert color="info" prominent>
        <h3 class="text-h5">Signing you in...</h3>
        <v-divider class="my-3"></v-divider>
        <div class="text-center">
          <v-progress-circular
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-alert>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AuthenticationCallback",
  data() {
    return {
      error: undefined,
    };
  },
  methods: {
    ...mapActions(["authenticateOidc", "oidcSignInCallback"]),
  },
  mounted() {
    this.oidcSignInCallback()
      .then((redirectPath) => {
        this.$router.push(redirectPath);
      })
      .catch((error) => {
        this.error = error;
      });
  },
};
</script>
