var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_c('v-navigation-drawer',{attrs:{"app":"","clipped":"","fixed":"","width":"180","mini-variant":_vm.navigationDrawerPinned,"permanent":"","touchless":""},on:{"update:miniVariant":function($event){_vm.navigationDrawerPinned=$event},"update:mini-variant":function($event){_vm.navigationDrawerPinned=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-toolbar',{attrs:{"dense":""}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-btn',{attrs:{"ripple":"","plain":"","icon":""},on:{"click":function($event){_vm.navigationDrawerPinned = !_vm.navigationDrawerPinned}}},[(_vm.navigationDrawerPinned)?_c('v-icon',[_vm._v(_vm._s(_vm.mdiChevronRight))]):_c('v-icon',[_vm._v(_vm._s(_vm.mdiChevronLeft))])],1):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"z-index":"220"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiDotsVertical))])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-action',[_c('v-switch',{attrs:{"dense":""},model:{value:(_vm.sortByOwner),callback:function ($$v) {_vm.sortByOwner=$$v},expression:"sortByOwner"}})],1),_c('v-list-item-title',[_vm._v("Sort Locations By Owner")])],1)],1)],1)],1)]},proxy:true}])},_vm._l((_vm.theaters()),function(theater,i){return _c('span',{key:i},[_c('v-list',{attrs:{"dense":"","nav":"","subheader":""}},[_c('v-subheader',[_vm._v(_vm._s(theater.name))]),_c('v-list-item',{attrs:{"to":{
            name: 'MapLocation',
            params: {
              name: theater.name,
            },
          }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Map")])],1)],1),_c('v-list-item',{attrs:{"to":{
            name: 'MapTheaterTableViewer',
            params: {
              name: theater.name,
            },
          }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Overview")])],1)],1),_vm._l((_vm.theaterDocuments(theater.name)),function(document,l){return _c('document-list',{key:'document_' + l,attrs:{"id":document.id,"route-name":"MapDocument"}})}),_vm._l((theater.locationGroups),function(locationGroup,j){return _c('v-list-group',{key:j,scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(locationGroup.name))])],1)]},proxy:true}],null,true)},_vm._l((_vm.sortedLocations(locationGroup)),function(location,k){return _c('v-list-item',{key:k,attrs:{"location":location,"to":{
              name: 'MapLocation',
              params: {
                name: theater.name,
                x: location.coordinatesInFalconUnits.x,
                y: location.coordinatesInFalconUnits.y,
              },
            }}},[_c('v-list-item-content',{attrs:{"two-line":""}},[_c('v-list-item-title',[_vm._v(_vm._s(location.name))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(location.owner)+" "),(location.tacanChannel && location.tacanBand)?_c('span',[_vm._v("  "+_vm._s(location.tacanChannel + location.tacanBand)+" ")]):_vm._e()])],1),(location.charts.length > 0)?_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"grey lighten-1","small":""}},[_vm._v(" "+_vm._s(_vm.mdiMap)+" ")])],1):_vm._e()],1)}),1)})],2),(i < Object.keys(_vm.theaters()).length - 1)?_c('v-divider'):_vm._e()],1)}),0),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }