<template>
  <div class="home">
    <v-container class="fill-height" fluid>
      <v-responsive>
        <v-main>
          <div class="text-center px-4">
            <v-img
              class="mx-auto"
              src="../assets/logo.svg"
              max-height="256"
              max-width="256"
            />
            <h1 class="v-heading text-h3 text-sm-h3 mb-4 pt-15">
              Electronic Flight Bag
            </h1>
            <!-- <div :class="[`text-h1`, `mb-2`]">Electronic Flight Bag</div> -->
            <p class="mx-auto">
              The
              <a
                href="https://en.wikipedia.org/wiki/NATO_phonetic_alphabet"
                rel="noreferrer"
                target="_blank"
              >
                <strong>Sierra Hotel</strong>
              </a>
              tool for
              <a
                href="https://www.falcon-bms.com/"
                rel="noreferrer"
                target="_blank"
              >
                <strong>Falcon BMS</strong>
              </a>
              pilots
            </p>
          </div>
        </v-main>
      </v-responsive>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Home extends Vue {}
</script>
