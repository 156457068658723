<template>
  <span>
    <l-layer-group ref="lines" name="Lines" layer-type="overlay">
      <l-polyline
        v-for="(line, index) in lineCoordinates"
        :key="'polyline_line_' + index"
        :lat-lngs="line"
        color="black"
        :weight="2"
        :opacity="0.8"
      ></l-polyline>
    </l-layer-group>
    <l-layer-group ref="threats" name="Threat Circles" layer-type="overlay">
      <!-- Leaflet defines radius in meters -->
      <!-- We convert it to feet -->
      <l-circle
        v-for="(threat, index) in threats"
        :key="'circlemarker_threat_' + index"
        :lat-lng="[threat.x, threat.y]"
        :radius="threat.radius"
        fill-color="red"
        :fill-opacity="0.2"
        color="red"
        :weight="1"
        :opacity="0.8"
      >
        <l-tooltip
          :options="{
            offset: [0, 0],
            permanent: true,
            direction: 'center',
          }"
        >
          {{ threat.title }}
        </l-tooltip>
      </l-circle>
    </l-layer-group>
    <l-layer-group
      ref="weaponTargets"
      name="Weapon Targets"
      layer-type="overlay"
    >
      <l-circle
        v-for="(weaponTarget, index) in weaponTargets"
        :key="'circle_weapontargets_' + index"
        :lat-lng="[weaponTarget.x, weaponTarget.y]"
        color="red"
        :weight="8"
        :opacity="0.8"
      >
        <l-tooltip
          :options="{
            offset: [10, 0],
            sticky: true,
          }"
        >
          {{ weaponTarget.title }}
        </l-tooltip>
      </l-circle>
    </l-layer-group>
    <l-layer-group ref="steerpoints" name="Steerpoints" layer-type="overlay">
      <l-circle
        v-for="(steerpoint, index) in steerpoints"
        :key="'circle_steerpoint_' + index"
        :lat-lng="[steerpoint.x, steerpoint.y]"
        color="magenta"
        :weight="2"
        :opacity="0.8"
      >
        <l-tooltip
          :options="{
            offset: [10, 0],
            sticky: true,
          }"
        >
          <span v-if="steerpoint.action">
            {{ steerpoint.title }} ({{ steerpoint.action }})
          </span>
          <span v-else>
            {{ steerpoint.title }}
          </span>
        </l-tooltip>
      </l-circle>
      <l-polyline
        :lat-lngs="steerpointCoordinates"
        color="magenta"
        :weight="2"
        :opacity="0.8"
      ></l-polyline>
    </l-layer-group>
  </span>
</template>

<script>
import { LCircle, LLayerGroup, LPolyline, LTooltip } from "vue2-leaflet";

export default {
  components: {
    LCircle,
    LLayerGroup,
    LPolyline,
    LTooltip,
  },
  computed: {
    steerpoints() {
      return this.$store.getters.steerpoints();
    },
    steerpointCoordinates() {
      return this.$store.getters
        .steerpoints()
        .map((steerpoint) => [steerpoint.x, steerpoint.y]);
    },
    weaponTargets() {
      return this.$store.getters.weaponTargets();
    },
    threats() {
      return this.$store.getters.threats();
    },
    lineCoordinates() {
      const linePoints = [];
      this.$store.getters
        .lines()
        .forEach((line) =>
          linePoints.push(
            line.points.map((linePoint) => [linePoint.x, linePoint.y]),
          ),
        );
      return linePoints;
    },
  },
};
</script>
