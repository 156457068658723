var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.document)?_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"to":{
          name: _vm.routeName,
          params: {
            id: _vm.id,
          },
        }}},[_vm._v(" "+_vm._s(_vm.document.name)+" ")])],1)]},proxy:true}],null,false,1204047098)},_vm._l((_vm.document.topics),function(topic,j){return _c('v-list-item',{key:j,attrs:{"to":{
      name: _vm.routeName,
      params: {
        id: _vm.id,
        page: topic.page,
      },
    }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(topic.title))])],1)],1)}),1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }