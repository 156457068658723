import "@/common/registerServiceWorker";
import "leaflet/dist/leaflet.css";
import "typeface-roboto/index.css";
import Vue from "vue";
import VueGtagPlugin from "vue-gtag";
import { sync } from "vuex-router-sync";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
sync(store, router);
App.use(
  VueGtagPlugin,
  {
    config: {
      id: "G-EXEENZCGJ8",
    },
  },
  router,
);

store.dispatch("readFiles").finally(() => {
  new Vue({
    store,
    router,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
