var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('l-layer-group',{ref:"lines",attrs:{"name":"Lines","layer-type":"overlay"}},_vm._l((_vm.lineCoordinates),function(line,index){return _c('l-polyline',{key:'polyline_line_' + index,attrs:{"lat-lngs":line,"color":"black","weight":2,"opacity":0.8}})}),1),_c('l-layer-group',{ref:"threats",attrs:{"name":"Threat Circles","layer-type":"overlay"}},_vm._l((_vm.threats),function(threat,index){return _c('l-circle',{key:'circlemarker_threat_' + index,attrs:{"lat-lng":[threat.x, threat.y],"radius":threat.radius,"fill-color":"red","fill-opacity":0.2,"color":"red","weight":1,"opacity":0.8}},[_c('l-tooltip',{attrs:{"options":{
          offset: [0, 0],
          permanent: true,
          direction: 'center',
        }}},[_vm._v(" "+_vm._s(threat.title)+" ")])],1)}),1),_c('l-layer-group',{ref:"weaponTargets",attrs:{"name":"Weapon Targets","layer-type":"overlay"}},_vm._l((_vm.weaponTargets),function(weaponTarget,index){return _c('l-circle',{key:'circle_weapontargets_' + index,attrs:{"lat-lng":[weaponTarget.x, weaponTarget.y],"color":"red","weight":8,"opacity":0.8}},[_c('l-tooltip',{attrs:{"options":{
          offset: [10, 0],
          sticky: true,
        }}},[_vm._v(" "+_vm._s(weaponTarget.title)+" ")])],1)}),1),_c('l-layer-group',{ref:"steerpoints",attrs:{"name":"Steerpoints","layer-type":"overlay"}},[_vm._l((_vm.steerpoints),function(steerpoint,index){return _c('l-circle',{key:'circle_steerpoint_' + index,attrs:{"lat-lng":[steerpoint.x, steerpoint.y],"color":"magenta","weight":2,"opacity":0.8}},[_c('l-tooltip',{attrs:{"options":{
          offset: [10, 0],
          sticky: true,
        }}},[(steerpoint.action)?_c('span',[_vm._v(" "+_vm._s(steerpoint.title)+" ("+_vm._s(steerpoint.action)+") ")]):_c('span',[_vm._v(" "+_vm._s(steerpoint.title)+" ")])])],1)}),_c('l-polyline',{attrs:{"lat-lngs":_vm.steerpointCoordinates,"color":"magenta","weight":2,"opacity":0.8}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }