<template>
  <span>
    <v-container>
      <v-row>
        <v-col>
          <v-data-table
            disable-pagination
            fixed-header
            :headers="headers"
            hide-default-footer
            item-key="campaignId"
            :items="locations"
            :search="search"
          >
            <template #top>
              <v-text-field v-model="search" label="Search"></v-text-field>
            </template>
            <template #[`item.name`]="{ item }">
              <router-link
                :to="{
                  name: 'MapLocation',
                  params: {
                    name,
                    x: item.coordinatesInFalconUnits.x,
                    y: item.coordinatesInFalconUnits.y,
                  },
                }"
              >
                <strong>{{ item.name }}</strong>
              </router-link>
            </template>
            <template #[`item.tacan`]="{ item }">
              <span v-if="item.tacanChannel">
                {{ item.tacanChannel + item.tacanBand }}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </span>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    locations() {
      return this.$store.getters.theaterLocations(this.name);
    },
  },
  data() {
    return {
      headers: [
        { text: "ID", value: "name" },
        { text: "LOC", value: "owner" },
        { text: "UHF", value: "towerFrequencyUhf", sortable: false },
        { text: "VHF", value: "towerFrequencyVhf", sortable: false },
        { text: "APPR", value: "approachFrequencyUhf", sortable: false },
        { text: "GND", value: "groundFrequencyUhf", sortable: false },
        { text: "ATIS", value: "atisFrequencyUhf", sortable: false },
        { text: "TACAN", value: "tacan", sortable: false },
        { text: "RANGE", value: "range", sortable: false },
        { text: "ILS 1", value: "runway1IlsFrequency", sortable: false },
        { text: "ILS 2", value: "runway2IlsFrequency", sortable: false },
        { text: "ILS 3", value: "runway3IlsFrequency", sortable: false },
        { text: "ILS 4", value: "runway4IlsFrequency", sortable: false },
      ],
      search: undefined,
    };
  },
};
</script>
