import { baseUrl } from "@/appUrl";
import { Category, DocumentFile } from "@/common/document-typings";
import {
  Location,
  LocationGroup,
  Theater,
  TheaterCollection,
} from "@/common/map-typings";
import {
  Line,
  MissionData,
  Steerpoint,
  Threat,
  WeaponTarget,
} from "@/common/mission-typings";
import { readIniFile } from "@/common/readIniFile";
import {
  TacticalReference,
  TacticalReferenceCategory,
  TacticalReferenceEntry,
  TacticalReferenceSubCategory,
} from "@/common/tacref-typings";
import Vue from "vue";
import Vuex from "vuex";
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import { oidcSettings } from "../plugins/oidc";

export const DOCUMENTS_UPDATE = "DOCUMENTS_UPDATE";
export const MAPS_UPDATE = "MAPS_UPDATE";
export const MISSION_DATA_UPDATE = "MISSION_DATA_UPDATE";
export const TACTICAL_REFERENCE_UPDATE = "TACTICAL_REFERENCE_UPDATE";

const readFile = (
  url: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
  callback: (data: any) => void,
): Promise<void> => {
  return fetch(url)
    .then((response) => response.json())
    .then(callback);
};

Vue.use(Vuex);

class State {
  categories: Category[] | null = null;
  theaterCollection: TheaterCollection | null = null;
  missionData: MissionData | null = null;
  tacticalReferences: TacticalReference[] | null = null;
}

const store = new Vuex.Store({
  modules: {
    oidcStore: vuexOidcCreateStoreModule(oidcSettings, {
      isAuthenticatedBy: "access_token",
      isPublicRoute: () => true,
    }),
  },
  plugins: [],
  state: new State(),
  getters: {
    categories: (state) => (): Category[] => {
      return state.categories ?? [];
    },
    category:
      (state, getters) =>
      (alias: string): Category => {
        return getters
          .categories()
          .find((category: Category) => category.alias === alias);
      },
    documents:
      (state, getters) =>
      (category?: string): DocumentFile[] => {
        if (category) {
          return getters.category(category)?.documents ?? [];
        } else {
          const documents = [];
          for (const category of getters.categories()) {
            documents.push(
              ...(getters.category(category.alias)?.documents ?? []),
            );
          }
          documents.push(...getters.theaterDocuments());
          return documents;
        }
      },
    document:
      (state, getters) =>
      (id: string): DocumentFile | null => {
        const document = getters
          .documents()
          .find((document: DocumentFile) => document.id === id);
        return document ?? null;
      },
    documentLocation:
      (state, getters) =>
      (id: string): string | null => {
        const filePath = getters.document(id)?.filePath;
        if (filePath) {
          return new URL(`./data/documents/${filePath}`, baseUrl).toString();
        }
        return null;
      },
    tacticalReferenceCategories: (state) => () => {
      return state.tacticalReferences?.[0]?.categories ?? [];
    },
    tacticalReferenceCategory:
      (state, getters) =>
      (categoryId: string): TacticalReferenceCategory | undefined => {
        return getters
          .tacticalReferenceCategories()
          .find(
            (category: TacticalReferenceCategory) => category.id === categoryId,
          );
      },
    tacticalReferenceSubCategory:
      (state, getters) =>
      (
        categoryId: string,
        subCategoryId: string,
      ): TacticalReferenceSubCategory | undefined => {
        const category = getters.tacticalReferenceCategory(categoryId);
        if (!category) {
          return undefined;
        }
        return category.categories.find(
          (category: TacticalReferenceSubCategory) =>
            category.id === subCategoryId,
        );
      },
    tacticalReferenceEntry:
      (state, getters) =>
      (
        categoryId: string,
        subCategoryId: string,
        entryId: string,
      ): TacticalReferenceEntry | undefined => {
        const subCategory = getters.tacticalReferenceSubCategory(
          categoryId,
          subCategoryId,
        );
        if (!subCategory) {
          return undefined;
        }
        return subCategory.entries.find(
          (entry: TacticalReferenceEntry) => entry.id === entryId,
        );
      },
    theaters: (state) => (): Theater[] => {
      return state.theaterCollection?.theaters ?? [];
    },
    theater:
      (state, getters) =>
      (name: string): Theater =>
        getters.theaters()?.find((theater: Theater) => theater.name === name) ??
        getters.theaters()[0],
    theaterDocuments:
      (state, getters) =>
      (name: string): DocumentFile[] => {
        if (name) {
          return getters.theater(name)?.documents ?? [];
        } else {
          const theaterDocuments = [];
          for (const theater of getters.theaters()) {
            theaterDocuments.push(
              ...(getters.theater(theater.name)?.documents ?? []),
            );
          }
          return theaterDocuments;
        }
      },
    theaterLocationGroups:
      (state, getters) =>
      (theaterName: string): LocationGroup[] =>
        getters.theater(theaterName)?.locationGroups ?? [],
    theaterLocations:
      (state, getters) =>
      (theaterName: string): Location[] => {
        let locations: Location[] = [];
        const locationGroups: LocationGroup[] =
          getters.theaterLocationGroups(theaterName);
        locationGroups.forEach(
          (locationGroup) =>
            (locations = locations.concat(locationGroup.locations)),
        );
        return locations;
      },
    theaterLocationByCoordinates:
      (state, getters) =>
      (theaterName: string, x: number, y: number): Location =>
        getters
          .theaterLocations(theaterName)
          .find(
            (location: Location) =>
              location.coordinatesInFalconUnits.x === x &&
              location.coordinatesInFalconUnits.y === y,
          ),
    theaterLocationByName:
      (state, getters) =>
      (theaterName: string, locationName: string): Location =>
        getters
          .theaterLocations(theaterName)
          .find((location: Location) => location.name === locationName),
    steerpoints: (state) => (): Steerpoint[] => {
      return state.missionData?.steerpoints ?? [];
    },
    weaponTargets: (state) => (): WeaponTarget[] => {
      return state.missionData?.weaponTargets ?? [];
    },
    threats: (state) => (): Threat[] => {
      return state.missionData?.threats ?? [];
    },
    lines: (state) => (): Line[] => {
      return state.missionData?.lines ?? [];
    },
  },
  mutations: {
    DOCUMENTS_UPDATE(state, categories) {
      state.categories = categories;
    },
    MAPS_UPDATE(state, theaterCollection) {
      state.theaterCollection = theaterCollection;
    },
    MISSION_DATA_UPDATE(state, missionData) {
      state.missionData = missionData;
    },
    TACTICAL_REFERENCE_UPDATE(state, tacticalReferences: TacticalReference[]) {
      state.tacticalReferences = tacticalReferences;
    },
  },
  actions: {
    clearMissionData(context) {
      context.commit(MISSION_DATA_UPDATE, null);
    },
    parseMissionData(context, binaryContents) {
      readIniFile(binaryContents, (missionData) =>
        context.commit(MISSION_DATA_UPDATE, missionData),
      );
    },
    readFiles(context) {
      if (!context.state.categories) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        readFile(
          new URL("./data/documents/documents.json", baseUrl).toString(),
          (data: DocumentFile) => {
            context.commit(DOCUMENTS_UPDATE, data.categories);
          },
        );
      }
      if (!context.state.theaterCollection) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        readFile(
          new URL("./data/maps/maps.json", baseUrl).toString(),
          (data: TheaterCollection) => {
            context.commit(MAPS_UPDATE, data);
          },
        );
      }
      if (!context.state.tacticalReferences) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        readFile(
          new URL("./data/tacref/tacref.json", baseUrl).toString(),
          (data: TacticalReference[]) => {
            context.commit(TACTICAL_REFERENCE_UPDATE, data);
          },
        );
      }
    },
  },
});

export default store;
