<template>
  <span>
    <v-navigation-drawer
      app
      clipped
      fixed
      width="180"
      :mini-variant.sync="navigationDrawerPinned"
      permanent
      touchless
    >
      <span v-for="(category, i) in tacticalReferenceCategories()" :key="i">
        <v-list dense nav subheader>
          <v-subheader>{{ category.name }}</v-subheader>
          <v-list-group
            v-for="(subCategory, j) in category.categories"
            :key="j"
          >
            <template #activator>
              <v-list-item-content>
                <v-list-item-title>{{ subCategory.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(entry, k) in sortedEntries(subCategory)"
              :key="k"
              :to="{
                name: 'TacticalReferenceViewer',
                params: {
                  categoryId: category.id,
                  subCategoryId: subCategory.id,
                  entryId: entry.id,
                },
              }"
            >
              <v-list-item-content two-line>
                <v-list-item-title>{{ entry.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ entry.owner }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-divider
          v-if="i < Object.keys(tacticalReferenceCategories()).length - 1"
        ></v-divider>
      </span>
      <template #append>
        <v-toolbar dense>
          <v-btn
            ripple
            plain
            icon
            @click="navigationDrawerPinned = !navigationDrawerPinned"
            v-if="$vuetify.breakpoint.mdAndDown"
          >
            <v-icon v-if="navigationDrawerPinned">{{ mdiChevronRight }}</v-icon>
            <v-icon v-else>{{ mdiChevronLeft }}</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
    </v-navigation-drawer>
    <router-view></router-view>
  </span>
</template>

<script lang="ts">
import { TacticalReferenceSubCategory } from "@/common/tacref-typings";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  computed: {
    ...mapGetters(["tacticalReferenceCategories"]),
  },
  data() {
    return {
      mdiChevronLeft: mdiChevronLeft,
      mdiChevronRight: mdiChevronRight,
      navigationDrawerPinned: false,
      sortByOwner: false,
    };
  },
  methods: {
    sortedEntries(category: TacticalReferenceSubCategory) {
      if (this.sortByOwner) {
        return [...category.entries].sort((a, b) => {
          if (a.owner && b.owner) {
            const byOwner = a.owner.localeCompare(b.owner);
            if (byOwner !== 0) {
              return byOwner;
            }
          }
          return a.name.localeCompare(b.name);
        });
      }
      return category.entries;
    },
  },
});
</script>
