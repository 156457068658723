import { baseUrl } from "@/appUrl";
import { VuexOidcClientSettings } from "vuex-oidc";

export const oidcSettings: VuexOidcClientSettings = {
  authority: "https://www.1stgw.com/open-id-configuration",
  clientId: "1stgw-efb",
  client_secret:
    "ckqgym13pohyfx53ta2syw5lsf5c12rbyzna46iygc4jyegvxc702316potv3kor",
  redirectUri: new URL("authentication", baseUrl).toString(),
  responseType: "code",
  scope: "openid nickname profile rank picture",
  automaticSilentRenew: true,
  automaticSilentSignin: false,
  loadUserInfo: false,
};
