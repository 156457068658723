<template>
  <v-container>
    <pdf
      v-if="uri"
      :key="dynamicKey"
      :src="uri"
      :page="page"
      :resize="resize"
      :scale.sync="scale"
      @numpages="pageCount = $event"
    >
      <template slot="loading">
        <v-skeleton-loader type="card, article, actions"></v-skeleton-loader>
      </template>
    </pdf>
    <v-bottom-navigation app fixed grow>
      <v-pagination
        v-model="selectedPage"
        :length="pageCount"
        :total-visible="10"
      >
      </v-pagination>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import router from "@/router";
import pdf from "pdfvuer";

function toHashCode(string) {
  let hashCode;
  for (let i = 0; i < string.length; i++) {
    hashCode = (Math.imul(31, hashCode) + string.charCodeAt(i)) | 0;
  }
  return hashCode;
}

export default {
  components: {
    pdf,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    page: {
      type: Number,
      default: 1,
      required: false,
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    dynamicKey() {
      return toHashCode(this.uri);
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    uri() {
      return this.$store.getters.documentLocation(this.id);
    },
    selectedPage: {
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      get() {
        return this.page;
      },
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      set(selectedPage) {
        router.push({
          name: "Document",
          params: {
            id: this.id,
            page: selectedPage,
          },
        });
      },
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      data: undefined,
      pageCount: 0,
      resize: true,
      scale: "page-width",
    };
  },
};
</script>
